<template>
  <ion-page>
    <Header title="Transaksi" />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list>
        <ion-item v-for="t in items" :key="t.id" button @click="detail(t.id)">
          <ion-thumbnail slot="start">
            <img :src="t.product.image">
          </ion-thumbnail>
          <ion-label>
            <h2>{{ t.product.name }}</h2>
            <ion-badge :color="color[t.status]">{{ status[t.status] }}</ion-badge>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll
        @ionInfinite="getTransaction"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonList, IonItem, IonThumbnail, IonLabel, IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent, IonBadge } from '@ionic/vue'
import { defineComponent } from 'vue'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    Header,
    IonList, IonItem, IonThumbnail, IonLabel,
    IonInfiniteScroll, IonInfiniteScrollContent,
    IonRefresher, IonRefresherContent, IonBadge
  },
  data () {
    return {
      items: [],
      page: 1,
      isDisabled: false,
      status: ["Di Batalkan", "Sedang Diproses", "Di Tunda", "Berhasil"],
      color: ["danger", "primary", "warning", "success"]
    }
  },
  mounted () {
    this.getTransaction()
  },
  methods: {
    async getTransaction(e) {
      let data = await this.$api.get('/api/store/transaction', {
          params: {page: this.page, filter: this.filter }
        }).then(res => res)

      if(data.meta.last_page == this.page) {
        this.isDisabled = true;
      }

      data.data.forEach(c => {
        this.items.push(c)
      })
      if(e && e.type == 'ion-infinite')
        e.target.complete();

      this.page++
    },
    async doRefresh(e) {
      this.page = 1;
      this.items = []
      this.isDisabled = false
      await this.getTransaction()
      e.target.complete();
    },
    detail(id) {
      this.$router.push({name: 'StoreTransactionDetail', params: { id: id}})
    }
  }
})
</script>

<style>

</style>
